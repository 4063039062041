import * as React from "react"
import { Link, graphql } from "gatsby"
import { Container, Col, Row, Badge, Card } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Share from "../components/Share"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Subscribe from "../components/Subscribe"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.notion
  const author = data.authorsYaml
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const image = getImage(post.childMarkdownRemark.localImage[0])

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.properties.excerpt.value}
        heroImage={post.childMarkdownRemark.localImage[0].publicURL}
        slug={post.properties.slug.value}
        author={author.name}
      />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <header>
                <p className="h4">
                  <Badge
                    text="light"
                    className={
                      "--nc-bg-" + post.properties.Category.value.color
                    }
                  >
                    {post.properties.Category.value.name}
                  </Badge>
                </p>
                <h1 itemProp="headline">{post.title}</h1>
                <p itemProp="excerpt">{post.properties.excerpt.value}</p>
              </header>
            </Col>

            <GatsbyImage image={image} alt={post.title} title={post.title} style={{ marginBottom: "80px" }}/>
            
          </Row>

          <Row>
            <Col lg="2" className="d-none d-lg-block">
              <Share
                location={location}
                url={"https://blog.trialfire.com/" + post.properties.slug.value}
                title={post.title}
                description={post.properties.excerpt.value}
              />
            </Col>
            <Col md="12" lg="7">
              <section
                dangerouslySetInnerHTML={{
                  __html: post.childMarkdownRemark.html,
                }}
                itemProp="articleBody"
              />

              <Bio
                name={author.name}
                bio={author.bio}
                id={author.yamlId}
                li={author.linkedin}
              />
            </Col>
            <Col lg="3">
              <div
                className="sticky-top d-none d-lg-block"
                style={{ paddingLeft: "1em" }}
              >
                <p
                  className="h4"
                  style={{
                    marginBottom: "0.5em",
                    marginTop: "0",
                    color: "#666",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  Get the latest from the Trialfire Blog
                </p>
                <Subscribe />
              </div>
            </Col>
          </Row>
          
        </Container>
      </article>
      <Container style={{ marginTop: "2rem" }}>
        <Row>
          <Col lg="6" style={{ marginTop: "2rem" }}>
            {previous && (
              <Card
                style={{
                  borderRadius: "0",
                  border: "0",
                  height: "100%",
                  backgroundImage: `url(${previous.childMarkdownRemark.localImage[0].publicURL})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <Card.Body
                  style={{
                    padding: "0",
                    background: "linear-gradient(357deg, black, #00000069)",
                  }}
                >
                  <Row>
                    <Col style={{ padding: "5% 10%" }}>
                      <span>
                        <Badge
                          className={
                            "--nc-bg-" +
                            previous.properties.Category.value.color
                          }
                          text="light"
                          style={{ fontFamily: "var(--font-heading)" }}
                        >
                          {previous.properties.Category.value.name}
                        </Badge>

                        <Link
                          to={"/" + previous.properties.slug.value}
                          itemProp="url"
                        >
                          <p
                            className="h1"
                            style={{
                              fontSize: "28px",
                              color: "#fff",
                              paddingTop: "4rem",
                            }}
                            itemProp="headline"
                          >
                            {previous.title}
                          </p>
                        </Link>
                        <p
                          itemProp="excerpt"
                          style={{
                            fontFamily: "var(--font-heading)",
                            fontSize: "var(--fontSize-2)",
                            lineHeight: "var(--lineHeight-1)",
                            color: "#fff",
                            maxWidth: "32rem",
                          }}
                        >
                          {previous.properties.excerpt.value}
                        </p>
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>

          <Col lg="6" style={{ marginTop: "2rem" }}>
            {next && (
              <Card
                style={{
                  borderRadius: "0",
                  border: "0",
                  height: "100%",
                  backgroundImage: `url(${next.childMarkdownRemark.localImage[0].publicURL})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <Card.Body
                  style={{
                    padding: "0",
                    background: "linear-gradient(357deg, black, #00000069)",
                  }}
                >
                  <Row>
                    <Col style={{ padding: "5% 10%" }}>
                      <span>
                        <Badge
                          className={
                            "--nc-bg-" + post.properties.Category.value.color
                          }
                          text="light"
                          style={{ fontFamily: "var(--font-heading)" }}
                        >
                          {next.properties.Category.value.name}
                        </Badge>

                        <Link
                          to={"/" + next.properties.slug.value}
                          itemProp="url"
                        >
                          <p
                            className="h1"
                            style={{
                              fontSize: "28px",
                              color: "#fff",
                              paddingTop: "4rem",
                            }}
                            itemProp="headline"
                          >
                            {next.title}
                          </p>
                        </Link>
                        <p
                          itemProp="excerpt"
                          style={{
                            fontFamily: "var(--font-heading)",
                            fontSize: "var(--fontSize-2)",
                            lineHeight: "var(--lineHeight-1)",
                            color: "#fff",
                            maxWidth: "32rem",
                          }}
                        >
                          {next.properties.excerpt.value}
                        </p>
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query (
    $pageId: String!
    $authorId: String
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }

    authorsYaml(yamlId: { eq: $authorId }) {
      yamlId
      name
      bio
      linkedin
    }

    previous: notion(id: { eq: $previousPostId }) {
      id
      title
      properties {
        slug {
          value
        }
        excerpt {
          value
        }
        Category {
          value {
            name
            color
          }
        }
      }
      childMarkdownRemark {
        localImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
    }

    next: notion(id: { eq: $nextPostId }) {
      id
      title
      properties {
        slug {
          value
        }
        excerpt {
          value
        }
        Category {
          value {
            name
            color
          }
        }
      }
      childMarkdownRemark {
        localImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
    }

    notion(id: { eq: $pageId }) {
      id
      title
      properties {
        Author {
          value {
            name
          }
        }
        excerpt {
          value
        }

        Category {
          value {
            name
            color
          }
        }

        excerpt {
          value
        }

        slug {
          value
        }
      }

      childMarkdownRemark {
        html
        id
        localImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
    }
  }
`
