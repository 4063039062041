
import * as React from "react"
import {
  Container,
  Col,
  Row,
  Image,
} from "react-bootstrap"

const Bio = ({ name, bio, id, li }) => {
  const avatar = "/avatars/" + id + ".jpeg"

  return (
    <div className="bio" style={{
        //borderTop: '1px solid #ccc', 
        padding:'2rem', 
        //borderBottom: '1px solid #ccc', 
        paddingBottom:'2rem', 
        fontFamily:'var(--font-heading)',
        backgroundColor:'#f9f9f9'
        }}>
      <Container style={{ paddingLeft: 0 }}>
        <Row>
          <Col lg={2} md={2} sm={2} xs={2}>
            <Image src={avatar} thumbnail title={name} alt={name}/>
          </Col>
          <Col>
            <p >
              
              <b itemProp="author">{name}{" "}</b> 
              <a
                target="_blank"
                href={li}
                rel="noreferrer"
            >
              <svg fill="#0d6efd" width="22px" role="img" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
            </a>
            </p>
            <p style={{lineHeight:'var(--lineHeight-tight)'}}> {bio}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Bio
